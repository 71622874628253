const format = require('date-format');

export function getCurrentDateTimeString() {
  return format.asString('yyyy-MM-dd hh:mm:ss', new Date());
};

export function getDateTimeString(date) {
  return format.asString('yyyy-MM-dd hh:mm:ss', date);
};

export function getDateString(date) {
  return format.asString('yyyy-MM-dd', date);
};
