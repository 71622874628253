import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  getAuthFromCookie,
  getUserFromCookie,
} from '@/utils/cookies';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/authentication/sign-in'
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(
      '@/views/NotFound'
    )
  },
  {
    path: '/home',
    component: () => import(
      '@/layouts/default/Index'
    ),
    meta: { auth: true },
    children: [
      {
        path: '/season',
        name: '서버&시즌 관리',
        component: () => import(
          '@/views/Season'
        ),
        meta: { auth: true }
      },
      {
        path: '/member',
        name: '회원 관리',
        component: () => import(
          '@/views/Member'
        ),
        meta: { auth: true }
      },
      {
        path: '/claim-prize',
        name: '상금 지급 관리',
        component: () => import(
          '@/views/ClaimPrize'
        ),
        meta: { auth: true }
      },
      {
        path: '/claim',
        name: '상금 클레임 관리',
        component: () => import(
          '@/views/Claim'
        ),
        meta: { auth: true }
      },
      {
        path: '/social',
        component: () => import(
          '@/views/social/Social'
        ),
        meta: { auth: true },
        children: [
          {
            path: '/social/list',
            name: '마이닝 리스트 관리',
            component: () => import(
              '@/views/social/SocialList'
            ),
            meta: { auth: true }
          },
          {
            path: '/social/participation',
            name: '미션 참여 관리',
            component: () => import(
              '@/views/social/SocialParticipation'
            ),
            meta: { auth: true }
          },
        ]
      },
      {
        path: '/image',
        name: '이미지 파일 관리',
        component: () => import(
          '@/views/Image'
        ),
        meta: { auth: true }
      },
      {
        path: '/prize',
        name: 'Prize',
        component: () => import(
          '@/views/Prize'
        ),
        meta: { auth: true }
      },
      {
        path: '/rank',
        name: 'Rank',
        component: () => import(
          '@/views/Rank'
        ),
        meta: { auth: true }
      },
      {
        path: '/alarm',
        name: 'Alarm',
        component: () => import(
          '@/views/Alarm'
        ),
        meta: { auth: true }
      },
      {
        path: '/transaction-land-history',
        name: 'TransactionLandHistory',
        component: () => import(
          '@/views/TransactionLandHistory'
        ),
        meta: { auth: true }
      },
    ]
  },
  {
    path: '/authentication',
    component: () => import(
      '@/layouts/authentication/Index'
    ),
    children: [
      {
        path: 'sign-in',
        name: 'SignIn',
        component: () => import(
          '@/views/authentication/SignIn'
        )
      },
      {
        path: 'sign-up',
        name: 'SignUp',
        component: () => import(
          '@/views/authentication/SignUp'
        )
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !getAuthFromCookie() && !getUserFromCookie()) { // 인증이 필요한 페이지이고, 로그인을 안했을 때 로그인 페이지로 넘긴다
    next('/authentication/sign-in');
    return;
  }

  next();
});


export default router;
