import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import {
  getAuthFromCookie,
  getUserFromCookie,
  saveAuthToCookie,
  saveUserToCookie
} from '@/utils/cookies';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  state: {
    username: getUserFromCookie() || '',
    token: getAuthFromCookie() ||  '',
    
    // SnackBar
    snackbar: {
      message: '',
      color: '',
      timeout: -1,
      show: false,
      leftTimeText: ''
    }
  },
  getters: {
    isLogin(state) {
      return state.username !== '';
    },
    isSnackBarShow(state) {
      return state.snackbar.show;
    }
  },
  mutations: {
    setUsername(state, username) {
      state.username = username;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearToken(state) {
      state.token = '';
    },
    showSnack(state, payload) {
      state.snackbar.message = payload.message || '';
      state.snackbar.color = payload.color || '';
      state.snackbar.timeout = payload.timeout || '';
      state.snackbar.show = true;
    },
    setSnackShow(state, payload) {
      state.snackbar.show = payload.show;
    },
    setSnackMessage(state, payload) {
      state.snackbar.message = payload.message || '';
      state.snackbar.leftTimeText = payload.leftTimeText || '';
    },
  },
  actions: {
    async LOGIN({ commit }, userData) {
      commit('setToken', userData.token);
      commit('setUsername', userData.user.userName);
      saveAuthToCookie(userData.token, 1);
      saveUserToCookie(userData.user.userName, 1);
      return userData;
    },
    async SHOW_SNACK({ commit }, payload) {
      commit('showSnack', payload);
    },
    async SET_SNACK_SHOW({ commit }, payload) {
      commit('setSnackShow', payload);
    },
    async SET_SNACK_MESSAGE({ commit }, payload) {
      commit('setSnackMessage', payload);
    },
  }
})

export default store


